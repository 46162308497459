import React, { useContext, useState, useEffect } from "react";
import "../../assets/style/globalStyle.scss";
import {
  updateTeamById,
  deleteTeam,
  searchUserName,
} from "../../services/teamService";
import { getAllEventsUpToDate } from "../../services/eventService";
import {
  GlobalStateContext,
  GlobalDispatchContext,
} from "../../context/GlobalContextProvider";
import ModalCard from "../../components/modalCard";

const UpdateTeamPage = () => {
  const GoToModal = async ({ modalType, showModal }) => {
    dispatch({
      type: "show_modal",
      payload: showModal,
      modalType: modalType,
    });
  };

  const dispatch = useContext(GlobalDispatchContext);
  const state = useContext(GlobalStateContext);

  const [players, setPlayers] = useState([]);
  const [eventsUpToDate, setEventsUpToDate] = useState([]);

  const [searchValue, setSearchValue] = useState("");
  const [updatedName, setUpdatedName] = useState("");
  const [eventLinked, setEventLinked] = useState(false);
  const [idEvent, setIdEvent] = useState("");
  const [eventSelected, setEventSelected] = useState([]);
  const [updatedListOfPlayer, setUpdatedListOfPlayer] = useState([]);
  const [listOfSelectedPlayer, setListOfSelectedPlayer] = useState([]);

  const handleUsername = async (searchValue) => {
    setSearchValue(searchValue);
    let searchResult = await searchUserName({ searchQuery: searchValue });
    if (searchResult.data) {
      setPlayers(searchResult.data);
    } else {
      return;
    }
  };

  const addPlayerToTeam = (player) => {
    setUpdatedListOfPlayer((updatedListOfPlayer) => [
      ...updatedListOfPlayer,
      player._id,
    ]);
    setListOfSelectedPlayer((listOfSelectedPlayer) => [
      ...listOfSelectedPlayer,
      player,
    ]);
  };

  const removePlayerFromTeam = (player) => {
    let tmp = updatedListOfPlayer.filter((item) => {
      return item !== player._id;
    });
    setUpdatedListOfPlayer(tmp);
    let tmpSelected = listOfSelectedPlayer.filter((item) => {
      return item !== player;
    });
    setListOfSelectedPlayer(tmpSelected);
  };

  const getListOfPlayerInfo = () => {
    setListOfSelectedPlayer(listOfPlayer);
    listOfPlayer.map((player) =>
      setUpdatedListOfPlayer((updatedListOfPlayer) => [
        ...updatedListOfPlayer,
        player._id,
      ])
    );
  };

  const getAllEventsToDisplay = async () => {
    const data = await getAllEventsUpToDate();

    if (!data) {
      return (
        <div className="container is-fullhd" style={{ width: "880px" }}>
          <h1>Pas d'event enregistré</h1>
        </div>
      );
    }
    setEventsUpToDate(data);
  };

  useEffect(() => {
    getListOfPlayerInfo();
    getAllEventsToDisplay();
    // eslint-disable-next-line
  }, []);

  if (!state) {
    return (
      <div className="container is-fullhd" style={{ width: "880px" }}>
        <h1>Pas d'équipes</h1>
      </div>
    );
  }

  const { _id, name, listOfPlayer } = state.team;

  return (
    <div className="box">
      <div className="field">
        <label className="label">
          Nom de l'équipe
          <input
            className="input"
            type="text"
            placeholder={name}
            value={updatedName}
            onChange={(e) => {
              setUpdatedName(e.target.value);
            }}
          />
        </label>
      </div>
      <br />

      <p className="label">Créer une team avec les joueurs d'un évènement</p>
      <div className="control columns">
        <div className="column">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={eventLinked === true}
              onChange={() => setEventLinked(true)}
            />
            <span style={{ marginLeft: "8px" }}>Oui</span>
          </label>
        </div>
        <div className="column">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={eventLinked === false}
              onChange={() => setEventLinked(false)}
            />
            <span style={{ marginLeft: "8px" }}>Non</span>
          </label>
        </div>
      </div>
      <br />

      {eventLinked && (
        <label className="label">
          Évènement séléctionné
          <br />
          <div
            className="select"
            value={idEvent}
            onChange={(e) => {
              setIdEvent(e.target.value);
              for (let i = 0; i < eventsUpToDate.events.length; i++) {
                if (eventsUpToDate.events[i]._id === e.target.value) {
                  console.log("eventselect", eventsUpToDate.events[i]);
                  setEventSelected(eventsUpToDate.events[i].listOfPlayer);
                  return;
                }
              }
            }}
          >
            <select>
              <option>Sélectionner l'event en question</option>
              {eventsUpToDate.length !== 0 &&
                eventsUpToDate.events.map((event) => (
                  <option key={event._id} value={event._id}>
                    {event.name}
                  </option>
                ))}
            </select>
          </div>
        </label>
      )}
      <br />

      <div className="panel">
        <div className="columns">
          <div className="column">
            <p className="title is-5">Joueurs à sélectionner</p>
            {!eventLinked ? (
              <>
                <div className="field">
                  <label className="label">
                    Rechercher par pseudo
                    <input
                      className="input"
                      type="text"
                      placeholder="Pseudo du joueur"
                      value={searchValue}
                      onChange={(e) => {
                        handleUsername(e.target.value);
                      }}
                    />
                  </label>
                </div>
                <div style={{ marginTop: "-10px", paddingLeft: "10px" }}>
                  {players.length !== 0 &&
                    players.map((player) => (
                      <div className="panel-block" key={player._id}>
                        <button
                          className="button is-success is-outlined mr-4"
                          onClick={() => addPlayerToTeam(player)}
                        >
                          +
                        </button>
                        <figure className="image is-48x48">
                          {player.image ? (
                            <img
                              className="is-rounded"
                              src={`https://res.cloudinary.com/ball-n-connect/image/upload/v1/profile_image/${player.userId}.jpg`}
                              alt=""
                            />
                          ) : (
                            <img
                              className="is-rounded"
                              src={"/assets/img/appareil_photo.png"}
                              alt=""
                            />
                          )}
                        </figure>
                        <p className="px-4">
                          {player.userName} {player.firstName} {player.lastName}
                        </p>
                      </div>
                    ))}
                </div>
              </>
            ) : eventSelected.length !== 0 ? (
              eventSelected.map((player) => (
                <div className="panel-block" key={player._id}>
                  <button
                    className="button is-success is-outlined mr-4"
                    onClick={() => addPlayerToTeam(player)}
                  >
                    +
                  </button>
                  <figure className="image is-48x48">
                    {player.image ? (
                      <img
                        className="is-rounded"
                        src={`https://res.cloudinary.com/ball-n-connect/image/upload/v1/profile_image/${player.userId}.jpg`}
                        alt=""
                      />
                    ) : (
                      <img
                        className="is-rounded"
                        src={"/assets/img/appareil_photo.png"}
                        alt=""
                      />
                    )}
                  </figure>
                  <p className="px-4">
                    {player.userName} {player.firstName} {player.lastName}
                  </p>
                </div>
              ))
            ) : (
              <p className="px-4">Pas de joueurs inscrits</p>
            )}
          </div>
          <div className="column">
            <p className="title is-5">Joueurs de l'équipe</p>
            <div>
              {listOfSelectedPlayer.length === 0 ? (
                <p className="subtitle is-6">Aucun</p>
              ) : (
                listOfSelectedPlayer.map((player) => (
                  <div className="panel-block" key={player._id}>
                    <button
                      className="button is-danger is-outlined mr-4"
                      onClick={() => removePlayerFromTeam(player)}
                    >
                      -
                    </button>
                    <figure className="image is-48x48">
                      {player.image ? (
                        <img
                          className="is-rounded"
                          src={`https://res.cloudinary.com/ball-n-connect/image/upload/v1/profile_image/${player.userId}.jpg`}
                          alt=""
                        />
                      ) : (
                        <img
                          className="is-rounded"
                          src={"/assets/img/appareil_photo.png"}
                          alt=""
                        />
                      )}
                    </figure>
                    <p className="px-4">
                      {player.userName} {player.firstName} {player.lastName}
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="columns">
        <div className="column">
          <button
            className="button is-primary is-outlined"
            onClick={() =>
              updateTeamById({
                teamId: _id,
                name: updatedName === "" ? name : updatedName,
                listOfPlayer: updatedListOfPlayer,
              })
            }
          >
            Enregistrer les modifications
          </button>
        </div>

        <div className="column">
          <button
            className="button is-danger is-outlined"
            onClick={() => GoToModal({ modalType: "delete", showModal: true })}
          >
            Supprimer l'équipe
          </button>
        </div>
      </div>

      <ModalCard
        isActiveClassName={state.showModal && state.modalType === "delete"}
        title="Supprimer"
        content="Voulez-vous supprimer cette équipe ?"
        validateClick={() => {
          deleteTeam({ teamId: _id });
          dispatch({
            type: "show_modal",
            payload: false,
            modalType: "",
          });
        }}
      />
    </div>
  );
};

export default UpdateTeamPage;
