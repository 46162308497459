import trackerApi from "../api/tracker";
import { navigate } from "gatsby";

export const addTeam = async ({ name, listOfPlayer }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const team = await trackerApi.post(
      "/addTeam",
      {
        token,
        name,
        listOfPlayer,
      },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    navigate("/app/allTeams");
    return team.data;
  } catch (error) {
    console.log("error can't create a team : ", error);
  }
};

export const getAllTeams = async () => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const response = await trackerApi.get("/getTeams", {
      headers: { Authorization: `Bearer ${token}` },
      params: { token },
    });
    return response.data;
  } catch (error) {
    console.log("error to get all teams :", error);
  }
};

export const getTeamById = async (id) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    const team = await trackerApi.get(`/getTeamById/${id}`, {
      headers: { Authorization: `Bearer ${token}` },
      params: { token, id },
    });
    return team.data;
  } catch (error) {
    console.log("error can't get a team : ", error);
  }
};

export const updateTeamById = async ({ teamId, name, listOfPlayer }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/updateTeam",
      {
        token,
        teamId,
        name,
        listOfPlayer,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allTeams");
  } catch (error) {
    console.log("ERROR can't update a team");
  }
};

export const deleteTeam = async ({ teamId }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;

  try {
    await trackerApi.post(
      "/deleteTeam/",
      {
        teamId,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    navigate("/app/allTeams");
  } catch (error) {
    console.log("ERROR can't delete team");
  }
};

export const searchUserName = async ({ searchQuery }) => {
  const user = window.localStorage.getItem("gatsbyUser");
  const token = JSON.parse(user).token;
  try {
    const res = await trackerApi.get("/searchUser", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: {
        token,
        searchQuery,
      },
    });
    return res;
  } catch (error) {
    console.log("ERROR can't find player's user name");
  }
};
