import React, { useContext } from "react";
import "../assets/style/globalStyle.scss";

import { GlobalDispatchContext } from "../context/GlobalContextProvider";

const ModalCard = ({ isActiveClassName, title, content, validateClick }) => {
  const dispatch = useContext(GlobalDispatchContext);

  let modalUpdateClassName = "modal";
  if (isActiveClassName) {
    modalUpdateClassName += " is-active";
  }

  const CloseModal = async ({ showModal }) => {
    dispatch({
      type: "show_modal",
      payload: showModal,
      modalType: "",
    });
  };

  return (
    <div className={modalUpdateClassName}>
      <div className="modal-background"></div>
      <div className="modal-card">
        <header className="modal-card-head">
          <p className="modal-card-title">{title}</p>
          <button
            className="delete"
            aria-label="close"
            onClick={() => CloseModal({ showModal: false })}
          ></button>
        </header>
        <section className="modal-card-body">{content}</section>
        <footer className="modal-card-foot">
          <button className="button is-success" onClick={validateClick}>
            Valider
          </button>
          <button
            className="button"
            onClick={() => CloseModal({ showModal: false })}
          >
            Annuler
          </button>
        </footer>
      </div>
    </div>
  );
};

export default ModalCard;
